.stats-card {
  background-color: $color-secondary-fade1;
  border-radius: 30px;
  padding: 30px;
  font-family: "Rubik" !important;
  max-height: 700px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 450px;
  background: url("../img/card-bg.png") no-repeat;
  background-size: cover;
  .content {
    width: 70%;
    .title {
      color: $color-primary;
      @include font-size(30px);
      font-weight: 500;
    }
    .text {
      @include font-size(18px);
      line-height: 150%;
      color: $color-darker;
    }
    .separator {
      background: $color-secondary;
      margin: 20px 0px;
      border: none;
      border-radius: 40px;
      width: 20%;
      height: 6px;
      margin: 15px 0px;
      display: block;
    }
  }
}
.wrapper {
  padding: 30px;
  font-family: "Rubik" !important;
  max-height: 700px;
  margin-bottom: 0px;
  height: 100%;
  min-height: 450px;
  .list-item {
    width: 100%;
    margin: auto auto;
    .separator {
      opacity: 0.2;
      background: $color-dark;
      border: none;
      border-radius: 40px;
      height: 2px;
      margin: 50px 0px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .item {
      font-family: "Rubik" !important;
      margin: 0px auto;
      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
      h4 {
        @include font-size(18px);
        margin: 10px 0px;
        text-align: center;
        color: $color-secondary;
      }
      p {
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        color: $color-darker;
        width: 70%;
        margin-bottom: 0px;
      }
    }
  }
}