.wrapper {
  margin-bottom: 50px;
}
.card-don {
  position: relative;
  height: 450px;
  width: 100%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-size: cover;
  transition: .30s;
}
.card-don-img-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: row;
  position: relative;
  max-height: 190px;
  height: 190px;
  width: 100%;
  left: 0;
  right: 0;
  padding-bottom: 30px;
}
.card-don-content {
  position: absolute;
  top: 0;
  left: 15px;
  display: flex;
  height: 400px;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 29px);
  height: 100%;
  overflow: hidden;
  .card-don-img {
    top: 0;
    width: 100%;
    display: block;
    object-fit: cover;
    transform: scale(1.01);
    position: absolute;
    height: 85%; 
  }
  .arrow-down {
    position: absolute;
    left: 0;
    right: 0;
    top: 15px;
    width: 90px;
    margin-left: auto;
    margin-right: auto;
  }
  .donate-info {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 30px;
    h4 {
      font-family: "League Gothic", sans-serif;
      color: white;
      font-weight: 400;
      font-size: 80px;
      line-height: 50px;
      @media (max-width:992px) {
        font-size: 60px;
      }
    }
    .white {
      margin: 5px 15px;
      @media (max-width: 992px) {
        margin: 2px 10px;
      }
      @media (max-width: 768px) {
        margin: 10px 15px 20px 15px;
      }
    }
    span {
      font-family: "League Gothic", sans-serif;
      font-weight: 400;
      font-size: 24px;
      text-align: center;
      @media (max-width:992px) {
        font-size: 20px;
      }
    }
    .card-text {
      z-index: 10;
      transition: .30s;
      text-align: center;
      margin: 5px 15px;
      font-family: "Source Sans Pro", sans-serif;
      font-weight: bold;
      color: #fff;
      @media (max-width: 992px) {
        width: 100%;
        margin: 2px 10px;
      }
      @media (max-width: 768px) {
        width: 100%;
        margin: 25px 20px;
      }
    }
  }
}