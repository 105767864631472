.graph {
    &.mb {
        @media (min-width: 991.98px) {
            display: none;
        }
    }
    &.d {
        @media (max-width: 991.98px) {
            display: none;
        }
    }
    font-family: "Rubik" !important;
    .wrapper {
        background-color: $color-secondary-fade1;
        border-radius: 20px;
        min-height: 270px !important;
    }
    .caption-text {
        width: 50%;
        @media (max-width: 991.98px) {
            width: 100%;
        }
    }
    &-title {
        margin: auto 0;
        h3 {
            @include font-size(30px);
            color: $color-secondary;
            text-transform: uppercase;
            line-height: 150%;
            display: block;
        }
        .separator {
            background: $color-primary;
            margin: 20px 0px;
            border: none;
            border-radius: 40px;
            width: 20%;
            height: 6px;
            margin: 15px 0px;
            display: block;
        }
    }
    &-circle {
        margin: auto 0;
        min-height: 200px;
        height: 200px;
    }
    &-caption {
        margin: auto 0;
        min-height: 200px;
        flex-direction: wrap;
        .caption-text {
            min-height: 75px;
        }
        h3 {
            @include font-size(30px);
            width: 50%;
            text-align: center;
            @media (max-width: 992px) {
                font-size: 30px;
                width: 50%;
                padding-right: 5px;
                text-align: right;
            }
        }
        p {
            width: 50%;
            color: $color-dark;
            @include font-size(12px);
            @media (max-width: 992px) {
                font-size: 12px;
            }
        }
        .t1 {
            color: $color-primary;
        }
        .t2 {
            color: $color-secondary;
        }
        .t3 {
            color: #2E8BB4;
        }
        .t4 {
            color: #69B8C1;
        }
    }
}