.sponsor {
  .container {
    @media (min-width: 576px) {
      border-radius: 30px;
      border: 1px solid rgba(112, 112, 112, 0.3);
    }
  }
    img {
    transform: scale(0.75);
    @media (max-width: 576px) {
      transform: scale(0.5);    
    }
  }
}