.event{
  font-family: "Rubik";
  .text {
    @include font-size(20px);
    line-height: 125%;
    color: $color-darker;
    position: relative;
  }
  // .text::after {
  //   width: 300px;
  //   height: 300px;
  //   position: absolute;
  //   bottom: -500px;
  //   z-index: 2;
  //   left: 0;
  //   content: "";
  //   background: url("../img/notedown.svg") no-repeat;
  //   background-size: contain;
  //   @media (max-width: 1270px) {
  //     width: 250px;
  //   }
  //   @media (max-width: 1200px) {
  //     width: 200px;
  //     bottom: -450px;
  //   }
  //   @media (max-width: 992px) {
  //     bottom: -350px;
  //   }
  //   @media (max-width: 768px) {
  //     display: none;
  //   }
  // }
  .f-letter {
    @include font-size(38px);
  }
  &-container {
    background-color: rgba(0, 113, 185, 0.05);
    border-radius: 30px;
    color: $color-secondary;
    text-align: center;
    height: 100%;
    padding: 16% 0px;
    @media (max-width: 768px) {
      // padding: 40px 0px;
    }
  }
  // &-container::after {
  //   width: 60%;
  //   height: 300px;
  //   position: absolute;
  //   bottom: -400px;
  //   z-index: 2;
  //   left: 50%;
  //   transform: translate(-50%, 0%);
  //   content: "";
  //   background: url("../img/notedown.svg") no-repeat;
  //   background-size: contain;
  // }
  // @media (max-width: 768px) {
  //   display: block;
  //   margin-bottom: calc(300px - 40%);
  // }
  &-item {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 22%;
    &-number {
      width: 20%;
      @include font-size(85px);
      line-height: 60px;
      color: $color-primary;
      margin: 0;
      @media (max-width: 768px) {
        font-size: 80px;
        width: 20%;
      }
    }
    &-img {
      width: 20%;
      @media (max-width: 768px) {
        width: 25%;
      }
    }
    &-title {
      @media (max-width: 768px) {
        width: 45%;
      }
      width: 40%;
      @include font-size(25px);
      line-height: 20px;
      margin: 0;
      text-align: left;
    }
  }
  &-separator {
    @media (max-width: 768px) {
      // margin: 40px 0px;
      margin-left: auto;
      margin-right: auto;  
    }
    background: $color-dark;
    border: none;
    width: 80%;
    height: 2px;
    margin: 6% 0px;
    display: block;
    opacity: 0.2;
    margin-left: auto;
    margin-right: auto;
  }
}