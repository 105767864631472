$color-primary: #63DBC7;
$color-secondary: #0071B9;
$color-secondary-fade: rgba(0, 113, 185, 0.1);
$color-secondary-fade1: rgba(0, 113, 185, 0.05);
$color-white: #ffffff;
$color-black: #000000;
$color-dark: #707070;
$color-darker: #686869;

$color-donate: #FD3433;
$color-donate-text: #DE969F;

$caption: (
first: #A6B7D1,
middle: #C7D4E6,
last: #ECF3FD
);
$slider: (
first: #A6B7D1,
middle: #C7D4E6,
last: #ECF3FD
);
$donate: (
first: #A70016,
middle: #840011,
last: #54000B
);
$stats: (
first: #FFFFFF,
middle: #EFF5FD,
last: #ECF3FD
);
$commitment: (
first: #1E2734,
last: #47576E
);
$footer: (
first: #1E2734,
middle: #0E1218,
last: #000000
);