nav {
  position: fixed;
  z-index: 500;
  background-color: $color-dark;
  top: 0;
  left: 0;
  height: 13vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
  @media (max-width: 992px) {
    padding-left: 10px;
    padding-right: 10px;
  }
  #logo {
    height: 50%;
    @media (max-width: 992px) {
      height: 21.5%;
      width: 100%;
    }
  }
}