header {
  position: relative;
  height: 75vh;
  background-image: url("../img/header-background.jpg");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  color: $color-white;
  margin-top: 13vh;
  .container {
    @media (max-width: 768px) {
      width: 100% !important;
      max-width: 100vw !important;
    }
  }
  @media (max-width: 768px) {
    background-image: url("../img/header-background-mb.jpg");
    background-position: 23% 0;
  }
  .container {
    @media (max-width: 768px) {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .text {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (max-width: 768px) {
      bottom: 20%;      
    }
  }
  h3 {
    @media (max-width: 768px) {
      @include font-size(23px);
      line-height: 26px;
    }
    @include font-size(29px);
    align-self: center;
    width: 100%;
    text-align: center;
    font-family: "Rubik";
    color: $color-white;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    margin-bottom: 5px; 
  }
  h1 {
    @media (max-width: 768px) {
      @include font-size(70px);
      padding: 20px 20px 0px 20px;
    }
    @include font-size(81px);
    width: 100%;
    text-align: center;
    font-family: "Rubik";
    color: $color-white;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    margin-bottom: 30px;
    &::after {
      background-image: url("../img/quote.svg");      
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      width: 50px;
      height: 50px;
      content: "";
      @media (max-width: 768px) {
        bottom: -85px;
        right: 45px;
      }
      bottom: 30px;
      right: 30px;
      transform: rotate(180deg);
      z-index: -1;
    }
    &::before {
      background-image: url("../img/quote.svg");
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      width: 50px;
      height: 50px;
      content: "";
      top: 60px;
      left: 35px;
      z-index: -1;
    }
  }
  .underline {
    width: 10%;
    height: 8px;
    display: block;
    background-color: $color-primary;
    border-radius: 28px;
    margin-left: auto;
    margin-right: auto;
    border: none;
  }
  #header-bottom-arrow {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 768px) {
      bottom: 20px;
    }
  }
}