.donate {
  font-family: "Rubik" !important;
  .intro {
    text-align: center;
    .title {
      @include font-size(35px);
    }
    .separator {
      background: $color-primary;
      border: none;
      border-radius: 40px;
      width: 10%;
      height: 6px;
      margin: 15px 0px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .text {
      @include font-size(18px);
      color: $color-darker;
    }
  }
  .card {
    @media (max-width: 992px) {
      width: 100%;
    }
    background: $color-secondary-fade;
    width: 20%;
    margin: 8px;
    border: none;
    border-radius: 20px;
    text-align: center;
    font-family: "Rubik";
    color: $color-dark;
    min-height: 166px;
    padding: 10px 10px;
    cursor: pointer;
    position: relative;
    &.time {
      &::after {
        width: 100px;
        height: 100px;
        position: absolute;
        top: -45px;
        z-index: 2;
        right: 10px;
        content: "";
        background: url("../img/timer.png") no-repeat;
        background-size: contain;
      }
    }
    h3 {
      @include font-size(40px);
    }
    p {
      @include font-size(18px);
      b {
        color: $color-secondary;
      }
    }
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      padding: 10px 0px;
      height: 8%;
      opacity: 0;
      width: 10.62%;
    }
    transition: .2s ease-in-out 0s;
    &:hover {
      color: $color-white;
      background-color: $color-secondary;
      box-shadow: 0px 0px 26px 6px rgba(0,0,0,0.38);
      b {
        color: $color-white;
      }
      img {
        opacity: 1;
      }
    }
    &.active {
      color: $color-white;
      background-color: $color-secondary;
      box-shadow: 0px 0px 26px 6px rgba(0,0,0,0.38);
      b {
        color: $color-white;
      }
      img {
        opacity: 1;
      }
    }
    &.custom {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      label {
        @include font-size(30px);
        width: 15%;
        line-height: 25px;
        text-align: center;
      }
      input {
        border-radius: 4px;
        font-family: "Rubik";   
        text-align: center;
        @include font-size(16px);
        border: none;
        padding: 10px;
        width: 85%;
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: $color-dark;
          text-align: center;
          @include font-size(16px);
          font-family: "Rubik";   
        }
        &::-moz-placeholder { /* Firefox 19+ */
          color: $color-dark;
          text-align: center;
          @include font-size(16px);
          font-family: "Rubik";   
        }
        &:-ms-input-placeholder { /* IE 10+ */
          color: $color-dark;
          text-align: center;
          @include font-size(16px);
          font-family: "Rubik";   
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: $color-dark;
          text-align: center;
          @include font-size(16px);
          font-family: "Rubik";   
        }
      }
    }
  }
}